import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  DKLabel,
  DKButton,
  DKIcon,
  DKLine,
  DKDataGrid,
  INPUT_TYPE,
  DKIcons,
  showAlert,
  DKTooltipWrapper,
  showLoader,
  removeLoader,
  showToast,
  TOAST_TYPE,
} from "deskera-ui-library";
import RouteManager from "../../managers/RouteManager";
import AppManager from "../../managers/AppManager";
import SideBarService from "../../services/sidebar";
import InviteService from "../../services/InviteService";
import InviteManager from "../../managers/InviteManager";

function InviteList(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const width = SideBarService.getContainerWidth() - 20;

  const [columns, setColumns] = useState([]);
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    RouteManager.setPresenter({ props: { ...props, history: history } });
    AppManager.scrollToTop();

    getColumns();
    getInvites();
  }, []);

  const getColumns = () => {
    const columns = [...InviteManager.getColumns()];
    columns.forEach((column) => {
      column.name = t(column.name);

      if (column.key === "action") {
        column.options = [
          {
            title: t("ACCEPT"),
            className: "bg-blue text-white fw-m mr-m",
            onClick: ({ rowData }) => {
              acceptInvite(rowData.inviteCode);
            },
          },
        ];
      }
    });

    setColumns(columns);
  };

  const getRowData = (invites) => {
    const rowData = [];
    if (Array.isArray(invites)) {
      invites.forEach((invite, idx) => {
        rowData.push({
          ...invite,
          sn: idx + 1,
          rowContextMenu: getRowContextMenu(),
        });
      });
    }

    return rowData;
  };

  const getRowContextMenu = () => {
    return [
      {
        title: t("REJECT"),
        icon: DKIcons.ic_close,
        className: " p-0",
        onClick: ({ rowData }) => {
          rejectInvite(rowData.id);
        },
      },
    ];
  };

  const getInvites = () => {
    showLoader();
    InviteService.getInvites().then(
      (response) => {
        removeLoader();
        setInvites(getRowData(response));
      },
      (error) => {
        removeLoader();
        setInvites([]);
      }
    );
  };

  const acceptInvite = (inviteCode) => {
    InviteService.acceptInvite(inviteCode).then(
      (response) => {
        getInvites();
      },
      (error) => {
        showAlert(t("ERROR_OCCURRED"), t("ACCEPT_INVITE_ERROR"));
      }
    );
  };

  const rejectInvite = (inviteId) => {
    InviteService.rejectInvite(inviteId).then(
      (response) => {
        getInvites();
      },
      (error) => {
        showAlert(t("ERROR_OCCURRED"), t("REJECT_INVITE_ERROR"));
      }
    );
  };

  return (
    <div className="parent-width" style={{ height: "100wh" }}>
      <div className="parent-width row fw-m fs-l">{t("INVITES")}</div>
      <DKDataGrid
        allowSearch={false}
        allowBulkOperation={false}
        needTrailingColumn={true}
        allowColumnSort={false}
        allowColumnEdit={false}
        columns={columns}
        rows={invites}
        width={width}
      />
    </div>
  );
}

export default InviteList;
